/**
 * Debug utilities for tracking performance issues and identifying server hangs
 */

// Enable or disable debugging based on environment
const DEBUG_ENABLED = process.env.NODE_ENV === 'development';

// Time threshold in ms to consider an operation "slow"
const SLOW_OPERATION_THRESHOLD = 500;

// Store for long running operations
interface OperationRecord {
  operationId: string;
  startTime: number;
  endTime?: number;
  duration?: number;
  metadata?: Record<string, any>;
}

// Keep track of all operations for analysis
const operationsRegistry: Record<string, OperationRecord> = {};

// Log with timestamp and optional color coding for console
export const debugLog = (message: string, level: 'info' | 'warn' | 'error' = 'info', metadata?: any) => {
  if (!DEBUG_ENABLED) return;
  
  const timestamp = new Date().toISOString();
  const logPrefix = `[DEBUG ${timestamp}]`;
  
  switch (level) {
    case 'warn':
      console.warn(logPrefix, message, metadata || '');
      break;
    case 'error':
      console.error(logPrefix, message, metadata || '');
      break;
    default:
      console.log(logPrefix, message, metadata || '');
  }
};

// Start timing an operation
export const startOperation = (operationName: string, metadata?: Record<string, any>): string => {
  if (!DEBUG_ENABLED) return '';
  
  const operationId = `${operationName}-${Date.now()}-${Math.random().toString(36).substr(2, 5)}`;
  
  operationsRegistry[operationId] = {
    operationId,
    startTime: performance.now(),
    metadata
  };
  
  debugLog(`Operation started: ${operationName}`, 'info', { operationId, ...metadata });
  
  return operationId;
};

// End timing an operation and report
export const endOperation = (operationId: string, additionalMetadata?: Record<string, any>) => {
  if (!DEBUG_ENABLED || !operationId) return;
  
  const operation = operationsRegistry[operationId];
  if (!operation) {
    debugLog(`Operation not found: ${operationId}`, 'error');
    return;
  }
  
  operation.endTime = performance.now();
  operation.duration = operation.endTime - operation.startTime;
  
  if (additionalMetadata) {
    operation.metadata = { ...operation.metadata, ...additionalMetadata };
  }
  
  // Log slow operations as warnings
  const logLevel = operation.duration > SLOW_OPERATION_THRESHOLD ? 'warn' : 'info';
  
  debugLog(
    `Operation completed in ${operation.duration.toFixed(2)}ms`, 
    logLevel, 
    { 
      operationId,
      duration: operation.duration,
      ...operation.metadata 
    }
  );
  
  // If operation is very slow, add more detailed logging
  if (operation.duration > SLOW_OPERATION_THRESHOLD * 2) {
    debugLog('❗ PERFORMANCE ALERT: Very slow operation detected', 'warn', operation);
  }
};

// Time a function execution
export function timeFunction<T extends (...args: any[]) => any>(
  fn: T, 
  functionName: string
): (...args: Parameters<T>) => ReturnType<T> {
  if (!DEBUG_ENABLED) return fn;
  
  return (...args: Parameters<T>): ReturnType<T> => {
    const operationId = startOperation(`Function: ${functionName}`);
    
    try {
      const result = fn(...args);
      
      // Handle promises
      if (result instanceof Promise) {
        return result
          .then(value => {
            endOperation(operationId, { status: 'resolved' });
            return value;
          })
          .catch(error => {
            endOperation(operationId, { status: 'rejected', error: error.message });
            throw error;
          }) as ReturnType<T>;
      }
      
      endOperation(operationId, { status: 'completed' });
      return result;
    } catch (error) {
      endOperation(operationId, { status: 'error', error: (error as Error).message });
      throw error;
    }
  };
}

// Track React component render time
export const trackComponentRender = (componentName: string) => {
  if (!DEBUG_ENABLED) return { measureRender: () => {} };
  
  const operationId = startOperation(`Render: ${componentName}`);
  
  return {
    measureRender: () => {
      endOperation(operationId);
    }
  };
};

// Memory usage monitoring
export const logMemoryUsage = () => {
  if (!DEBUG_ENABLED || typeof window === 'undefined') return;
  
  if (performance && (performance as any).memory) {
    const memory = (performance as any).memory;
    debugLog('Memory usage', 'info', {
      totalJSHeapSize: formatBytes(memory.totalJSHeapSize),
      usedJSHeapSize: formatBytes(memory.usedJSHeapSize),
      jsHeapSizeLimit: formatBytes(memory.jsHeapSizeLimit),
      percentUsed: ((memory.usedJSHeapSize / memory.jsHeapSizeLimit) * 100).toFixed(2) + '%'
    });
  }
};

// Helper to format bytes to human-readable format
const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// API call tracking wrapper
export const trackApiCall = async <T>(
  apiCallFn: () => Promise<T>,
  endpoint: string,
  params?: Record<string, any>
): Promise<T> => {
  const operationId = startOperation('API Call', { endpoint, params });
  
  try {
    const response = await apiCallFn();
    endOperation(operationId, { status: 'success' });
    return response;
  } catch (error) {
    endOperation(operationId, { 
      status: 'error', 
      error: (error as Error).message 
    });
    throw error;
  }
};

// Periodically check for long-running operations that might indicate hangs
let hangDetectionInterval: number | null = null;

export const startHangDetection = (thresholdMs: number = 10000) => {
  if (!DEBUG_ENABLED || hangDetectionInterval !== null) return;
  
  debugLog('Starting hang detection', 'info', { thresholdMs });
  
  hangDetectionInterval = window.setInterval(() => {
    const now = performance.now();
    const hangingSuspects: OperationRecord[] = [];
    
    Object.values(operationsRegistry).forEach(operation => {
      // Only look at operations that haven't completed
      if (!operation.endTime) {
        const duration = now - operation.startTime;
        if (duration > thresholdMs) {
          hangingSuspects.push({
            ...operation,
            duration
          });
        }
      }
    });
    
    if (hangingSuspects.length > 0) {
      debugLog(
        `❌ POTENTIAL HANG DETECTED: ${hangingSuspects.length} operations running longer than ${thresholdMs}ms`,
        'error',
        hangingSuspects
      );
      
      // Log memory usage when hangs are detected
      logMemoryUsage();
    }
  }, 5000) as unknown as number; // Check every 5 seconds
};

export const stopHangDetection = () => {
  if (hangDetectionInterval !== null) {
    clearInterval(hangDetectionInterval);
    hangDetectionInterval = null;
    debugLog('Hang detection stopped', 'info');
  }
};

// Initialize debug tools
export const initializeDebugTools = () => {
  if (!DEBUG_ENABLED) return;
  
  debugLog('Debug tools initialized', 'info', { 
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    screen: {
      width: window.screen.width,
      height: window.screen.height
    }
  });
  
  // Start hang detection
  startHangDetection();
  
  // Log memory usage periodically
  setInterval(logMemoryUsage, 60000); // Every minute
  
  // Listen for unhandled errors and promise rejections
  window.addEventListener('error', (event) => {
    debugLog('Unhandled error', 'error', {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: event.error?.stack || 'No stack trace available'
    });
  });
  
  window.addEventListener('unhandledrejection', (event) => {
    debugLog('Unhandled promise rejection', 'error', {
      reason: event.reason?.stack || event.reason || 'Unknown reason'
    });
  });
};
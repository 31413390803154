/**
 * Environment Variables Utility
 * Provides structured access to environment variables and API keys
 */

/**
 * Supabase configuration
 */
export const supabase = {
  url: process.env.REACT_APP_SUPABASE_URL || '',
  key: process.env.REACT_APP_SUPABASE_KEY || '',
  
  /**
   * Checks if Supabase is properly configured
   */
  isConfigured: (): boolean => {
    return Boolean(process.env.REACT_APP_SUPABASE_URL && process.env.REACT_APP_SUPABASE_KEY);
  }
};

/**
 * SendGrid configuration (legacy)
 */
export const sendgrid = {
  apiKey: process.env.REACT_APP_SENDGRID_API_KEY || '',
  
  /**
   * Checks if SendGrid is properly configured
   */
  isConfigured: (): boolean => {
    return Boolean(process.env.REACT_APP_SENDGRID_API_KEY);
  }
};

/**
 * Mailgun configuration
 */
export const mailgun = {
  apiKey: process.env.MAILGUN_API_KEY || '',
  domain: process.env.MAILGUN_DOMAIN || 'mg.moonmanifest.com',
  sender: process.env.MAILGUN_VERIFIED_SENDER || 'Moon Manifest <postmaster@mg.moonmanifest.com>',
  
  /**
   * Checks if Mailgun is properly configured
   */
  isConfigured: (): boolean => {
    // For frontend, we don't need the API key since we use the Netlify function
    return true;
  }
};

/**
 * Logger utility for environment variable issues
 */
export const checkEnvironmentVariables = (): void => {
  // Check Supabase configuration
  if (!supabase.isConfigured()) {
    console.warn('Supabase environment variables are missing or incomplete.');
    
    if (!process.env.REACT_APP_SUPABASE_URL) {
      console.warn('Missing REACT_APP_SUPABASE_URL');
    }
    
    if (!process.env.REACT_APP_SUPABASE_KEY) {
      console.warn('Missing REACT_APP_SUPABASE_KEY');
    }
  }

  // Check SendGrid configuration (deprecated but still checked for backwards compatibility)
  if (!sendgrid.isConfigured()) {
    // Reducing this to a debug log since we're moving to Mailgun
    console.debug('SendGrid API key is missing. This is expected as the system now uses Mailgun.');
  }
  
  // No need to check Mailgun in the frontend since it uses the Netlify function
  // which handles the API key configuration
};

// Export environment check function as default
export default checkEnvironmentVariables;

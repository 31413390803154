/**
 * SendGrid Email Client Configuration (DEPRECATED)
 * 
 * This module is maintained for backward compatibility but now redirects
 * to the Mailgun implementation. The original SendGrid dependency has been removed.
 * 
 * @deprecated Use mailgunClient instead
 */

// Import the new Mailgun client implementation
import { sendEmail as mailgunSendEmail } from './mailgunClient';
// We need sendgridConfig for isConfigured() check in the compatibility layer
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { sendgrid as sendgridConfig } from './env';

// Log a warning about the deprecated client
console.warn(
  'SendGrid client is deprecated and will be removed in a future version. ' +
  'Please use the Mailgun client instead.'
);

/**
 * Send an email (redirects to Mailgun)
 * 
 * This is a compatibility layer that redirects to the Mailgun implementation
 * 
 * @param {Object} options - Email options
 * @param {string} options.to - Recipient email address
 * @param {string} options.from - Sender email address
 * @param {string} options.subject - Email subject
 * @param {string} options.text - Plain text content
 * @param {string} options.html - HTML content (optional)
 * @returns {Promise} - API response
 */
export const sendEmail = async ({
  to,
  from,
  subject,
  text,
  html,
}: {
  to: string;
  from: string;
  subject: string;
  text: string;
  html?: string;
}) => {
  // Log a deprecation warning
  console.warn(
    'Using deprecated SendGrid client. Please update your code to use the Mailgun client directly.'
  );
  
  // Forward the request to the Mailgun client
  return mailgunSendEmail({
    to,
    from,
    subject,
    text,
    html
  });
};

// Create a named export object
const sendgridClient = {
  sendEmail,
};

// Export the named object as default
export default sendgridClient;

/**
 * Example usage:
 * 
 * import { sendEmail } from './utils/sendgridClient';
 * 
 * // Send an email
 * const result = await sendEmail({
 *   to: 'recipient@example.com',
 *   from: 'your-verified-sender@example.com', // Must be verified in SendGrid
 *   subject: 'Hello from Moon Manifest',
 *   text: 'This is a test email from Moon Manifest.',
 *   html: '<p>This is a <strong>test email</strong> from Moon Manifest.</p>', // Optional
 * });
 * 
 * if (result.success) {
 *   console.log('Email sent successfully');
 * } else {
 *   console.error('Failed to send email:', result.error);
 * }
 */
